import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { getPreviewForOverviewType, getSingletonTypeBySlug } from '/machinery/groqSnippets'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Quote } from '/components/pageOnly/Quote'
import { ItemsCarousel } from '/components/buildingBlocks/ItemsCarousel'
import { Hero } from '/components/expertise/Hero'
import { Content } from '/components/expertise/Content'
import { PageLayout } from '/components/case/pageOnly/PageLayout'
import { ContainerDark, ContainerLight } from '/components/home/Container'
import { ContactBlock, getContactPerson } from '/components/expertise/ContactBlock'
import { FrequentlyAskedQuestions } from '/components/expertise/FrequentlyAskedQuestions'
import { Icon } from '/components/buildingBlocks/Icon'
import { BlockContent } from '/components/buildingBlocks/BlockContent'
import { useIsPreview } from '/machinery/useIsPreview.js'

import phoneIcon from '/components/expertise/images/phone.raw.svg'
import enveloppeIcon from '/components/expertise/images/enveloppe.raw.svg'
import isEmpty from 'lodash/isEmpty'
import groq from 'groq'
import CustomersAndAwards from '/components/pageOnly/CustomersAndAwards.universal'

import mailIcon from '/components/expertise/images/mail-letter.raw.svg'
import styles from './Expertise.css'


const query = groq`
  ${getSingletonTypeBySlug('expertise')} {
    "id": _id,
    title,
    introduction,
    contactBlockTitle,
    content,
    headerImage,
    headerVideo,
    meta,
    slug,
    quote,
    contactPerson,
    category,
    faq {
      'items': questions[] {
        _key,
        question,
        answer[]
      }
    },
    "carouselCases": ${getPreviewForOverviewType('case')} | order(documentOrder) {
      'slug': slug.current,
      'id': _id,
      'client': header.client,
      'image': header.headerImage {..., asset->},
      title,
      categories
    },
    "relatedArticles": ${getPreviewForOverviewType('article')} | order(documentOrder) [0...10] {
      'slug': slug.current,
      'id': _id,
      'client': header.client,
      'image': header.headerImage {..., asset->},
      title,
      contributors[]-> {
        _id,
        firstName,
        role,
      },
    }
  }
`
Expertise.meta = {
  async fetchData(sanityClient, { isPreview = false, params: { slug } }) {
    const data = await sanityClient.fetch(query, { isPreview, slug })
    return { data, isPreview, status: isEmpty(data) ? 404 : 200 }
  }
}

export function Expertise() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle || data?.title)
  const isPreview = useIsPreview()
  const { title, headerImage, headerVideo, introduction, contactPerson, content, carouselCases = [], faq, quote, category, contactBlockTitle } = data || {}
  const filteredCases = carouselCases?.filter(item =>  item?.categories?.includes(category))
  const sources = {
    1080: headerVideo?.quality1080,
    720: headerVideo?.quality720,
    540: headerVideo?.quality540,
    360: headerVideo?.quality360
  }

  return (
    <div className={styles.page}>
      <PageLayout>
        <section className={styles.hero} data-x='hero'>
          <Hero image={headerImage} {...{ title, sources }} />
        </section>
        <ContainerLight>
          {introduction && (
            <section className={styles.introduction} data-x='introduction'>
              <BlockContent blocks={introduction} />
            </section>
          )}
          <section className={styles.contact} data-x='contact'>
            <ContactBlock title={contactBlockTitle} name={contactPerson} />
          </section>
          <section className={styles.contentAndContact} data-x='content-and-contact'>
            <ContentAndContact name={contactPerson} layoutClassName={styles.contentAndContactLayout} {...{ content }} />
          </section>
        </ContainerLight>
        {quote?.quote &&  (
          <section className={styles.quote} data-x='quote'>
            <Quote
              quote={quote?.quote}
              cite={quote?.cite}
              quatationColor={quote?.quatationColor}
              image={quote?.image}
            />
          </section>
        )}
        <ContainerDark>
          {(!isPreview && filteredCases.length > 0) && (
            <section className={styles.cases} data-x='related-cases'>
              <ItemsCarousel title='Bekijk ons werk' cases={filteredCases} type='case' />
            </section>
          )}
          <section section data-x='customers-and-awards'>
            <CustomersAndAwards />
          </section>
          {faq?.items && (
            <section className={styles.faq}>
              <FrequentlyAskedQuestions
                contactName={getContactPerson(contactPerson).name}
                contactFunction={getContactPerson(contactPerson).job}
                {...{ faq }}
              />
            </section>
          )}
        </ContainerDark>
      </PageLayout>
    </div>
  )
}

function ContentAndContact({ content, layoutClassName, name }) {
  return (
    <div className={cx(styles.componentContentAndContact, layoutClassName)}>
      <Content
        title={content?.title}
        content={content?.content}
        layoutClassName={styles.contentLayout}
      />
      <div className={styles.contactBlockWrapper}>
        <ContactBlockSmall {...{ name }}  />
      </div>
    </div>
  )
}

function ContactBlockSmall({ name }) {
  return (
    <div className={styles.componentContactBlockSmall}>
      <Icon layoutClassName={styles.iconLayout} icon={mailIcon} />
      <h3>Neem contact op met {getContactPerson(name).name}, {getContactPerson(name).job}</h3>
      <div className={styles.icons}>
        <a
          className={styles.icon}
          href='tel:+31302876400'
          data-x='link-to-phone'
          data-x-context='contactblock-small'
        >
          <Icon icon={phoneIcon} />
        </a>
        <a
          className={styles.icon}
          href='mailto:hallo@kaliber.net'
          data-x='link-to-email'
          data-x-context='contactblock-small'
          target='_blank'
          rel="noreferrer"
        >
          <Icon icon={enveloppeIcon} />
        </a>
      </div>
    </div>
  )
}
